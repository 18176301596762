
import { Vue, Prop, Component } from 'vue-property-decorator';
import last from 'lodash/last';
import { FormFieldValidaiton, KeyValue } from '@/components/form/form.types';
import { randId } from '@/utils/components';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import KeyValueBody from '@/components/form/KeyValueInput/KeyValueBody.vue';
import * as types from '@/components/form/form.types';
import { ValidationFlags } from 'vee-validate/dist/types/types';

type VO = InstanceType<typeof ValidationObserver>;

@Component({
  components: {
    KeyValueBody,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class KeyValueInput extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: KeyValue[];
  @Prop({ type: String, required: true, default: '' }) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly keyLabel!: string;
  @Prop({ type: String, default: '' }) readonly valueLabel!: string;
  @Prop({ type: String, default: 'text' }) readonly keyType!: string;
  @Prop({ type: String, default: 'text' }) readonly valueType!: string;
  @Prop({ type: Object, required: false }) validation?: FormFieldValidaiton;
  @Prop({ type: Object, required: false }) keyValidation?: FormFieldValidaiton;
  @Prop({ type: Object, required: false }) valueValidation?: FormFieldValidaiton;
  @Prop({ type: Boolean }) readonly required!: boolean;
  @Prop({ type: Boolean }) readonly fieldLabels!: boolean;
  @Prop({ type: String, default: '' }) readonly helperText!: string;
  @Prop({ type: String, default: randId() }) readonly id!: string;
  @Prop({ type: Boolean }) readonly persistentHelperText!: boolean;
  @Prop({ type: Boolean }) readonly allowEmpty!: boolean;

  formError(vo?: { errors: VO['errors'] } & ValidationFlags): string {
    const errors = vo?.errors[this.id];
    return errors?.[0] || '';
  }

  isHidden = false;

  get enableAddRow(): boolean {
    if (this.allowEmpty) {
      return true;
    }
    const l = last(this.value);
    if (!l) {
      // when it's an empty array we already
      // have a placeholder there
      return false;
    }

    // not both empty
    return Boolean(l.value || l.key);
  }

  get normalizedValidation(): FormFieldValidaiton {
    if (this.validation === undefined) {
      return {};
    }
    const validation: types.FormFieldValidaiton = { ...this.validation };
    if (validation.name === undefined) {
      validation.name = this.label;
    }
    if (validation.vid === undefined) {
      validation.vid = this.id;
    }

    return validation;
  }

  update(value: KeyValue[]): void {
    this.$emit('input', value);
  }

  remove(i: number): void {
    this.update(this.value.filter((field, idx) => idx !== i));
    this.removeEmpty();
  }

  removeEmpty(): void {
    this.$nextTick(() => {
      if (!this.allowEmpty) {
        if (!document.querySelector(`#${this.id}:focus-within`)) {
          this.update(
            this.value.filter(kv => {
              return kv.key !== '' || kv.value !== '';
            }),
          );
        }
      }
    });
  }

  add(): void {
    // nextTick adds the input after the blur handler fires
    this.$nextTick(() => {
      this.$emit('input', this.value.concat([{ key: '', value: '', id: randId() }]));
    });
  }
}
