
import { Vue, Prop, Component } from 'vue-property-decorator';
import { randId } from '@/utils/components';
import { FormFieldValidaiton, KeyValue } from '@/components/form/form.types';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: {
    GTextfield,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class KeyValueRow extends Vue {
  @Prop({ type: Object, required: true }) value!: KeyValue;
  @Prop({ type: Object, required: false }) keyValidation?: FormFieldValidaiton;
  @Prop({ type: Object, required: false }) valueValidation?: FormFieldValidaiton;
  @Prop({ type: String, required: false, default: '' }) keyLabel!: string;
  @Prop({ type: String, required: false, default: '' }) valueLabel!: string;
  @Prop({ type: String, default: 'text' }) keyType!: string;
  @Prop({ type: String, default: 'text' }) valueType!: string;
  @Prop({ type: String, required: true }) fieldKey!: string;
  @Prop(Boolean) isPlaceholder!: boolean;

  $refs!: {
    observer?: InstanceType<typeof ValidationObserver>;
  };

  get id(): string {
    return randId();
  }

  get isPersistentHelperText(): boolean {
    return !!(this.keyValidation || this.valueValidation);
  }

  get normalizedKeyValidation(): FormFieldValidaiton | Record<string, never> {
    if (this.keyValidation === undefined || this.isPlaceholder) {
      return {};
    }
    const k = this.fieldKey + ':key';
    const validation: FormFieldValidaiton = { ...this.keyValidation, vid: k };

    if (validation.name === undefined) {
      validation.name = this.keyLabel || ' ';
    }

    return validation;
  }

  get normalizedValueValidation(): FormFieldValidaiton | Record<string, never> {
    if (this.valueValidation === undefined || this.isPlaceholder) {
      return {};
    }
    const k = this.fieldKey + ':value';
    const validation: FormFieldValidaiton = { ...this.valueValidation, vid: k };

    if (validation.name === undefined) {
      validation.name = this.valueLabel || ' ';
    }

    return validation;
  }

  emit(type: string, arg: Partial<KeyValue>): void {
    this.$emit(type, { ...this.value, ...arg });
  }

  handleValueBlur(evt: unknown) {
    this.$emit('blur', evt);
    this.$refs.observer?.validate();
  }

  changeKey(key: string): void {
    this.emit('input', { key });
  }

  changeValue(value: string): void {
    this.emit('input', { value });
  }

  remove(): void {
    this.$emit('remove');
  }
}
